import React, { useEffect, useState } from 'react';
import './ProductFilter.css';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Button from '../../Button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaFilter } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { setWindowWidth } from '../../../stores/slice';


export default function ProductFilter({ categories, onFilterChange, inStockCount, outOfStockCount, categoryId, setFilters }) {
    const [priceRange, setPriceRange] = useState([0, 1000]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [isPriceOpen, setPriceOpen] = useState(true);
    // const [availability, setAvailability] = useState('');
    const [isCategoryOpen, setCategoryOpen] = useState(true);
    const [isStockOpen, setStockOpen] = useState(true);
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const dispatch = useDispatch();
    const windowWidth = useSelector((state => state.store.Screenwidth));
    const location = useLocation();

    useEffect(() => {
        const defaultPriceRange = [0, 1000];
        setPriceRange(defaultPriceRange);
    }, [location.pathname])

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            if (newWidth !== windowWidth) {
                dispatch(setWindowWidth(newWidth));
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [windowWidth, dispatch]);

    useEffect(() => {
        setSelectedCategory(parseInt(categoryId));
    }, [categoryId, selectedCategory]);

    const navigate = useNavigate();

    const handlePriceChange = (newRange) => {
        setPriceRange(newRange);
    };

    const replaceSpacesWithHyphens = (str) => {
        return str.replace(/\s+/g, '-').toLowerCase();
    }

    const handleCategoryChange = (id , title) => {
        replaceSpacesWithHyphens(title);
        navigate(`/category/${replaceSpacesWithHyphens(title)}/${id}`);
        setSelectedCategory(id);
    };

    const toggleSection = (section) => {
        if (section === 'price') {
            setPriceOpen(!isPriceOpen);
        } else if (section === 'category') {
            setCategoryOpen(!isCategoryOpen);
        }
        else if (section === 'stock') {
            setStockOpen(!isStockOpen);
        }
    };

    // const handleStock = (name, option) => {
    //     setAvailability(name);

    //     onFilterChange({
    //         availability: name,
    //         priceRange,
    //         selectedCategory
    //     });

    //     if (option) {
    //         navigate(`/shop/${option}`);
    //     }

    //     else if (!option){
    //         navigate('/shop');
    //     }
    // }

    const applyFilters = () => {
        onFilterChange({
            priceRange,
            selectedCategory
        });
        setSidebarOpen(false);
    };

    useEffect(() => {
        if (windowWidth > 550) {
            setSidebarOpen(false);
        }
    }, [windowWidth])

    const handleResetFilters = () => {
        const defaultPriceRange = [0, 1000];
        setPriceRange(defaultPriceRange);
        setSelectedCategory(0);

        onFilterChange({
            priceRange: defaultPriceRange,
            selectedCategory: 0,
        });

        navigate('/shop');
    };

    return (
        <>
            <button
                className="mobile-filter-button"
                onClick={() => setSidebarOpen(!isSidebarOpen)}
            >  Filter <FaFilter />
            </button>
            <div className={isSidebarOpen ? 'filter-sidebar-open' : 'filter-sidebar'}>
                {isSidebarOpen ? <div className='close-icon-product-filter' onClick={() => setSidebarOpen(false)} >×</div> : null}
                {/* <div className="filter-section">
                    <div className="filter-heading" onClick={() => toggleSection('stock')}>
                        Avaliability <span className={isStockOpen ? 'arrow-up' : 'arrow-down'}></span>
                    </div>
                    {isStockOpen && (
                        <>
                            <div className="avaliability-div">
                                <p onClick={() => handleStock(true, 'instock')} className={availability === true ? 'avaliability-para-selected' : 'avaliability-para'} >  In Stock ({inStockCount})  </p>
                                <p onClick={() => handleStock(false, 'outstock')} className={availability === false ? 'avaliability-para-selected' : 'avaliability-para'} > Out Of Stock ({outOfStockCount})</p>
                                <p onClick={() => handleStock('')} className='avaliability-para' >Total Stock</p>
                            </div>
                        </>
                    )}
                </div> */}

                <div className="filter-section">
                    <div className="filter-heading" onClick={() => toggleSection('category')}>
                        Category <span className={isCategoryOpen ? 'arrow-up' : 'arrow-down'}></span>
                    </div>
                    {isCategoryOpen && (
                        <div>
                            {categories.map((category, index) => (
                                <p
                                    key={index}
                                    className={`filter-section-p ${selectedCategory === parseInt(category?.Id) ? 'active' : ''}`}
                                    onClick={() => handleCategoryChange(category?.Id , category?.CategoryName)}
                                >
                                    {category?.CategoryName}
                                </p>
                            ))}
                        </div>
                    )}
                </div>

                <div className="filter-section">
                    <div className="filter-heading" onClick={() => toggleSection('price')}>
                        Price <span className={isPriceOpen ? 'arrow-up' : 'arrow-down'}></span>
                    </div>
                    {isPriceOpen && (
                        <>
                            <div className="price-range">
                                <Slider
                                    range
                                    min={0}
                                    max={1000}
                                    value={priceRange}
                                    onChange={handlePriceChange}
                                    trackStyle={[{ backgroundColor: 'var(--primary-text-color)' }]}
                                    handleStyle={[
                                        { borderColor: 'var(--hover-color)' },
                                        { borderColor: 'var(--hover-color)' }
                                    ]}
                                    railStyle={{ backgroundColor: '#ddd' }}
                                />
                            </div>
                            <div className="price-values">
                                <span>${priceRange[0]}</span> &nbsp;  - &nbsp; <span>${priceRange[1]}</span>
                            </div>
                        </>
                    )}
                </div>

                <div>
                    <Button
                        text="Filter"
                        style={{ width: '100%', height: '30px', lineHeight: 0, padding: '0rem' }}
                        onClick={applyFilters}
                        className='filter-btn'
                    />
                </div>

                <div>
                    <p className='reset-filters-btn' onClick={handleResetFilters} >Reset Filters</p>
                </div>
            </div>
        </>
    );
}
