import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from '../Pages/HomePage/HomePage'
import ShoppingPage from '../Pages/ShoppingPage/ShoppingPage'
import ProductPage from '../Pages/ProductPage/ProductPage';
// import CartPage from '../Pages/CartPage/CartPage';
import About from '../Pages/About/About';
import Contact from '../Pages/Contact/Contact';
// import WishList from '../Pages/WishList/WishList';
import ScrollToTop from '../Component/ScrollToTop/ScrollToTop';
// import BlogPage from '../Pages/BlogPage/BlogPage';
import SizePage from '../Pages/SizePage/SizePage';
import PrivacyPolicyPage from "../Pages/PrivacyPolicy/PrivacyPolicyPage"
import TermsConditions from "../Pages/PrivacyPolicy/TermsConditionsPage"
import RefundPolicy from "../Pages/PrivacyPolicy/RefundPolicyPage";
// import CheckoutForm from '../Component/CheckoutComponents/CheckoutForm/CheckoutForm';
import CheckoutPage from "../Pages/CheckoutPage/CheckoutPage"

import ResettingState from '../Component/ResettingState/ResettingState';
import OrderSummary from '../Pages/OrderSummary/OrderSummary';

export default function Routing() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/shop" element={<ShoppingPage />} />
                <Route path='/category/:title/:CategoryId' element={<ShoppingPage />} />
                <Route path='/:title/:id' element={<ProductPage />} />
                <Route path='/search/:keywords' element={<ShoppingPage />} />
                {/* <Route path = '/shop/instock' element={<ShoppingPage />} />
                <Route path = '/shop/outstock' element={<ShoppingPage />} /> */}
                <Route path='/about' element={<About />} />
                <Route path='/contact' element={<Contact />} />
                <Route path="/sizechart" element={<SizePage />} />
                <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
                <Route path="/termsconditions" element={<TermsConditions />} />
                <Route path='/refundpolicy' element={<RefundPolicy />} />
                <Route path="/checkout" element={<CheckoutPage />} />
                <Route path = '/orderSummary' element= {<OrderSummary />} />
                <Route path='/resetredux' element={<ResettingState />} />
                
                   {/* <Route path="/blogs" element={<BlogPage />} /> */}
            </Routes>
        </BrowserRouter>
    )
}
