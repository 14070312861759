import React from "react";
import "./OrderStatus.css";

const OrderStatus = ({ currentStatus }) => {
    const steps = [
        { id: 49, status: "On Progress" },
        { id: 50, status: "Assigned" },
        { id: 51, status: "Delivered" },
        { id: 52, status: "Cancelled" }
    ];

    // Find the index of the current step
    const currentStepIndex = steps.findIndex(step => step.id === currentStatus);

    const getStepClassName = (index) => {
        if (index < currentStepIndex) {
            return "step completed";
        }
        if (index === currentStepIndex) {
            return "step completed";
        }
        return "step";
    };

    return (
        <div className="order-status">
            <div className="progress-bar">
                {steps.map((step, index) => (
                    <div key={step.id} className={getStepClassName(index)}>
                        <div className="circle">
                            {index <= currentStepIndex ? (
                                <span>&#10003;</span> 
                            ) : (
                                index + 1
                            )}
                        </div>
                        <span className="label">{step.status}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OrderStatus;
