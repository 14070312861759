// MeasurementForm.js
import React from 'react';
import './MeasurementForm.css';

export default function MeasurementForm({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="size-modal-overlay" onClick={onClose}>
      <div className="size-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button>
        <div className="measurement-form">
          <div className="form-row">
            <div className="input-field">
              <label>Chest*</label>
              <div className="input-container">
                <input type="number" placeholder="in / cm" className="measurement-input" />
              </div>
            </div>
            <div className="input-field">
              <label>Height*</label>
              <div className="input-container">
                <input type="number" placeholder="in / cm" className="measurement-input" />
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="input-field">
              <label>Natural Waist*</label>
              <div className="input-container">
                <input type="number" placeholder="in / cm" className="measurement-input" />
              </div>
            </div>
            <div className="input-field">
              <label>Weight*</label>
              <div className="input-container">
                <input type="number" placeholder="lbs" className="measurement-input" />
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="input-field">
              <label>Lower Waist*</label>
              <div className="input-container">
                <input type="number" placeholder="in / cm" className="measurement-input" />
              </div>
            </div>
            <div className="input-field">
              <label>Shoulder</label>
              <div className="input-container">
                <input type="number" placeholder="in / cm" className="measurement-input" />
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="input-field">
              <label>Hips</label>
              <div className="input-container">
                <input type="number" placeholder="in / cm" className="measurement-input" />
              </div>
            </div>
            <div className="input-field">
              <label>Sleeves</label>
              <div className="input-container">
                <input type="number" placeholder="in / cm" className="measurement-input" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
