import React, { useEffect, useState } from 'react';
import './ProductPage.css';
import Navbar from "../../Component/Navigation/Navbar/Navbar";
import SingleProduct from '../../Component/SingleProductComponents/SingleProduct/SingleProduct';
import Footer from "../../Component/Footer/Footer";
import { useParams } from 'react-router-dom';
import { GET_METHOD } from '../../api/api';
import Loader from '../../Component/Loader/Loader';

export default function ProductPage() {
    const { id } = useParams();
    const itemId = parseInt(id);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                try {
                    const res = await GET_METHOD(`https://knitting.azurewebsites.net/api/ECommerceApi/GetItemCategoryWise?CampusId=50&CategoryId=0`);
                    const foundProduct = res?.find((item) => item?.ItemId === itemId);
                    if (foundProduct) {
                        setData([foundProduct]);
                        setLoading(false);
                    } else {
                        setData([]);
                        setLoading(false);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        }
        fetchData();
    }, [id, itemId]);

   
    return (
        <div style={{ backgroundColor: 'var(--background-color-2)', width: "100%" }} >
            {loading && <Loader />}
            <Navbar />
            <div className='sub-single-product-data'>
                <SingleProduct data={data} />
                <Footer />
            </div>
        </div>
    );
}
