import { useDispatch } from 'react-redux';
import { resetState } from '../../stores/slice.js';
import Button from "../Button/Button.jsx"

export default function ResettingState(){
    const dispatch = useDispatch();

    const handleReset = () => {
        dispatch(resetState());
    };

    return (
        <Button style={{color : 'var(--primary-text-color)'}} onClick={handleReset} text="Reset Btn" />
    );
};
