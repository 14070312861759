import React from "react"
import './App.css';
import Routing from './Routing/Routing';

function App() {
  return (
    <div className="App">
      <Routing /> 
    </div>
  );
}

export default App;
