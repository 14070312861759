import React, { useState, useEffect } from 'react';
import "./CheckoutDelieveryForm.css"
import { GET_METHOD } from "../../../api/api";

export default function CheckoutDelieveryForm({ onChange }) {

    const [country, setCountry] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [city, setCity] = useState("");
    const [email, setEmail] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [state, setState] = useState("");
    const [description , setDescription] = useState('');
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const res = await GET_METHOD(`https://knitting.azurewebsites.net/api/ECommerceApi/GetCountry`);
            setCountryData(res);
        }
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const res = await GET_METHOD(`https://knitting.azurewebsites.net/api/ECommerceApi/GetState?CountryId=${country}`)
            setStateData(res);
        }
        fetchData();
    }, [country])

    useEffect(() => {
        const fetchData = async () => {
            const res = await GET_METHOD(`https://knitting.azurewebsites.net/api/ECommerceApi/GetCity?StateId=${state}`);
            setCityData(res);
        }
        fetchData();
    }, [state])


    const handleForm = () => {
        onChange({
            country: country,
            firstName: firstName,
            lastName: lastName,
            address: address,
            phone: phone,
            city: city,
            postalCode: postalCode,
            email: email,
            description : description
        });
    }

    // console.log(country, 'country');

    return (
        <div className='main-checkout-delievery-form'>
            <form onChange={handleForm}>
                <h4 className='checkout-form-heading'>Delievery </h4>
                <input
                    type='text'
                    placeholder='Enter email '
                    className='checkoutform-big-input'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <div className='checkoutform-double-input' >
                    <input
                        type='text'
                        placeholder='First Name'
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />

                    <input
                        type='text'
                        placeholder='Last Name'
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </div>

                <select
                    className='checkoutform-big-select'
                    onChange={(e) => setCountry(e.target.value)}
                    value={country}
                >
                    <option value="" disabled >
                        Select Country/Region
                    </option>
                    {countryData?.map((country) => {
                        return (
                            <option value={country?.CountryId} >{country?.CountryName}</option>
                        )
                    })}
                </select>

                <select
                    className='checkoutform-big-select'
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                >
                    <option value="" disabled >
                        Select State
                    </option>

                    {stateData?.map((state) => {
                        return (
                            <option value={state?.StateId}> {state?.StateName} </option>
                        )
                    })}

                </select>


                <div className='checkoutform-double-input' >

                    <select
                        className='checkoutform-select'
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    >
                        <option value="" disabled >
                            Select City
                        </option>

                        {cityData?.map((city) => {
                            return (
                                <option value={city?.CityId}> {city?.CityName} </option>
                            )
                        })}

                    </select>

                    <input
                        type='text'
                        placeholder='Postal Code (optional)'
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                    />
                </div>

                <input
                    className='checkoutform-big-input'
                    placeholder='Complete Address'
                    type='text'
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />

                <input
                    placeholder='Phone'
                    className='checkoutform-big-input'
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />

                <textarea
                    placeholder='Description'
                    className='checkoutform-big-input'
                    style={{height : '50px'}}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />

            </form>
        </div>
    );
}


