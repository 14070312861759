import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { wishlistRemoveAllProducts, wishlistRemoveProducts } from '../../../stores/slice';
import Button from '../../Button/Button';
import { FaCartArrowDown } from 'react-icons/fa';

export default function WishlistSlider({ isOpen, onClose }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const wishlist = useSelector((state) => state.store.wishlist);
    const [isClosing, setIsClosing] = useState(false);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            onClose();
        }, 100);
    };

    const handleDelete = (id) => {
        dispatch(wishlistRemoveProducts({ ItemId: id }));
    };

    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('modal-overlay')) {
            setIsClosing(true);
            handleClose();
        }
    };


    if (!isOpen && !isClosing) return null;


    return (
        <div className={`modal-overlay ${isClosing ? 'modal-overlay-closing' : ''}`} onClick={handleOverlayClick} >
            <div className={`modal-content ${isClosing ? 'main-cart-closing' : ''}`}>
                {wishlist?.length > 0 ?
                    <div className='main-cart'>
                        <button className="close-modal" onClick={handleClose}>×</button>
                        <h1 className='cart-heading'>Your Wishlist</h1>
                        <div className='sub-cart'>
                            {wishlist?.map((item, index) => {
                                const totalProductPrice = item?.purchaseQuantity * item?.price;
                                return (
                                    <div className='cart-data' key={index}>
                                        <div className='cart-img-div'>
                                            <img src={item?.img} className='cart-item-image' alt='product-img' />
                                        </div>
                                        <div className='cart-item-data'>
                                            <p className='cart-item-title' onClick={() => navigate(`/singleproduct/${item?.ItemId}`)}>{item?.title}</p>
                                            {item?.size ? <p> <b>Size :</b>   {item?.sizeValue}</p> : null}
                                            {item?.color ? <p> <b>Color :</b>  {item?.colorValue}</p> : null}
                                            <p> <b>Price :</b>  ${totalProductPrice}</p>
                                            <p className='remove-text' onClick={() => handleDelete(item?.ItemId)} >Remove</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div>
                            <button
                                className='clear-cart-btn'
                                style={{ padding: '0rem', lineHeight: '0' }}
                                onClick={() => dispatch(wishlistRemoveAllProducts())}
                            >
                                Clear Wishlist
                            </button>
                        </div>
                    </div>
                    :
                    <div className='main-cart-emtpy'>
                        <button className="close-modal" onClick={handleClose}>×</button>
                        <h1>Your Wishlist is empty</h1>
                        <FaCartArrowDown style={{ textAlign: 'center', fontSize: '3em', color: 'var(--grey-text-color)', marginBottom: '2rem' }} />
                        <Button text="Continue Shopping" onClick={() => {
                            handleClose();
                            navigate("/shop");
                        }} />
                    </div>
                }
            </div>
        </div>
    )
}
