import React from 'react'
import './About.css'
// import About1 from '../../Component/AboutComponents/About1/About1'
import Navbar from '../../Component/Navigation/Navbar/Navbar'
// import About2 from '../../Component/AboutComponents/About2/About2'
import Footer from "../../Component/Footer/Footer"
import AllAboutLeather from '../../Component/AboutComponents/AllAboutLeather/AllAboutLeather'
// import Banner from "../../Component/Banner/Banner"

export default function About() {
  return (
    <div style={{ width: '100%' }}>
      <Navbar />
      <div className='sub-about-page'>
        {/* <About1 />
        <About2 /> */}
        <AllAboutLeather /> 
        <Footer />
      </div>
    </div>
  )
}
