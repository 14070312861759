import React from 'react'
import Header from '../../Component/HomePageComponents/Header/Header'
import Navbar from '../../Component/Navigation/Navbar/Navbar'
import SalientFeatures from '../../Component/HomePageComponents/SalientFeatures/SalientFeatures'
import Footer from '../../Component/Footer/Footer'
import ShopNow from '../../Component/HomePageComponents/ShopNow/ShopNow'
// import ProductCategories from '../../Component/HomePageComponents/ProductCategories/ProductCategories'
import CategoryComponent from '../../Component/HomePageComponents/CategoryComponent/CategoryComponent'
import OurBestSellers from '../../Component/HomePageComponents/OurBestSellers/OurBestSellers'
import ContactForm from '../../Component/ContactComponents/ContactForm/ContactForm'

export default function HomePage() {
    return (
        <div style={{width : '100%'}}>
            <Navbar />
            <Header />
            <CategoryComponent /> 
            <OurBestSellers /> 
            <ShopNow />
            <ContactForm /> 
            <SalientFeatures />
            <Footer />
        </div>
    )
}
