import React from 'react'
import "./PopupForm.css"
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaTimesCircle } from "react-icons/fa";

export default function PopupForm({ showPopup, message, onClose, title, sucess }) {
    if (!showPopup) return null;
    return (
        <div className='main-popup-form-overlay'>
            <div className='popup-form-content'>
                <button className="popup-form-close-btn" onClick={onClose}>×</button>
                <div className='popup-form-text'>
                    <h1>{title}</h1>
                    <p>{message}</p>
                    {sucess ? <IoIosCheckmarkCircle size={50} color='green' /> : <FaTimesCircle size={50} color='red' />}
                </div>
            </div>
        </div>
    )
}
