import React, { useEffect } from "react";
import "./CheckoutProducts.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Button/Button";
import { setCheckOutInformation, setDelieveryInformation } from "../../../stores/slice";
import { POST_METHOD } from "../../../api/api";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Loader from "../../Loader/Loader";
import PopupForm from "../../CartComponents/PopupForm/PopupForm";

export default function CheckoutProducts() {
  const cart = useSelector((state) => state.store.cart);
  const checkoutInformation = useSelector(
    (state) => state.store.checkoutInformation
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const arrayTotal = (arr) => arr.reduce((total, num) => total + num, 0);
  const subTotal = arrayTotal(
    cart.map((item) => item.purchaseQuantity * item.price)
  );
  const delieveryInfo = useSelector(
    (state) => state.store.delieveryInformation
  );

  const [loading, setLoading] = useState(false);

  const [popup, setPopup] = useState(false);

  useEffect(() => {
    dispatch(setCheckOutInformation({ cart, delieveryInfo }));
  }, [cart, dispatch, delieveryInfo]);

  const country = checkoutInformation.delieveryInfo?.country;
  const state = checkoutInformation.delieveryInfo?.state;
  const firstName = checkoutInformation.delieveryInfo?.firstName;
  const lastName = checkoutInformation.delieveryInfo?.lastName;
  const postalCode = checkoutInformation.delieveryInfo?.postalCode;
  const phone = checkoutInformation.delieveryInfo?.phone;
  const city = checkoutInformation.delieveryInfo?.city;
  const address = checkoutInformation.delieveryInfo?.address;
  const email = checkoutInformation.delieveryInfo?.email;

  console.log(
    "country",
    country,
    "state",
    state,
    "firstname",
    firstName,
    "lastname",
    lastName,
    "postalcode",
    postalCode,
    "phone",
    phone,
    "city",
    city,
    "address",
    address,
    "email",
    email
  );

  const HandleButton = async () => {
    console.log("checkOutInformation", checkoutInformation);

    const country = checkoutInformation.delieveryInfo?.country;
    const state = checkoutInformation.delieveryInfo?.state;
    const firstName = checkoutInformation.delieveryInfo?.firstName;
    const lastName = checkoutInformation.delieveryInfo?.lastName;
    const postalCode = checkoutInformation.delieveryInfo?.postalCode;
    const phone = checkoutInformation.delieveryInfo?.phone;
    const city = checkoutInformation.delieveryInfo?.city;
    const address = checkoutInformation.delieveryInfo?.address;
    const email = checkoutInformation.delieveryInfo?.email;

    if (
      country === "" ||
      firstName === "" ||
      lastName === "" ||
      postalCode === "" ||
      phone === "" ||
      address === "" ||
      email === "" ||
      checkoutInformation.delieveryInfo === undefined ||
      checkoutInformation.delieveryInfo.length === 0
    ) {
      setPopup(true);
      setTimeout(() => setPopup(false), 5000);
      return;
    }

    const ItemArray = checkoutInformation.cart?.map((cart) => {
      let obj = {
        ItemID: cart?.ItemId,
        Qty: cart?.purchaseQuantity,
        Discount: 0,
        Description: cart.sizeValue + " " + cart.colorValue,
      };
      return obj;
    });

    const OptionsArray = checkoutInformation.cart?.map((cart) => {
      let obj = {
        ItemID: 0,
        SpecificationID: 0,
      };
      return obj;
    });

    console.log("ItemArray", JSON.stringify(ItemArray));
    console.log("OptionsArray", JSON.stringify(OptionsArray));

    setLoading(true);
    const res = await POST_METHOD(
      `https://knitting.azurewebsites.net/api/ECommerceApi/CreateCart?SessionID=0&CustomerID=100&LanguageID=100&CartDescription='All Specification Detail'&ItemArray=${JSON.stringify(
        ItemArray
      )}&OptionsArray=${JSON.stringify(
        OptionsArray
      )}&SelectStatus=Website&totalDiscount=0&CampusId=50&CountryId=${country}&StateId=${state}&CityId=${city}&FirstName=${firstName}&LastName=${lastName}&Address=${address}&PostalCode=${postalCode}&PhoneNo=${phone}&Email=${email}`,
      {}
    );
    console.log(res.data);

    if (res.success) {
      navigate("/orderSummary", { state: { checkoutData: res.data } });
      setLoading(false);
      dispatch(setDelieveryInformation([]));
      dispatch(setCheckOutInformation([]));
    } else {
      console.log("checkout failed");
    }
  };

  return (
    <div className="main-checkout-products">
      {loading && <Loader />}
      <div className="sub-checkout-products">
        {cart.length === 0 && (
          <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
            No products found in your cart
          </h1>
        )}
        {cart.map((item) => {
          const totalProductPrice = item.purchaseQuantity * item.price;
          return (
            <div className="single-product-info" key={item.id}>
              <div className="checkout-products-information">
                <div className="checkout-products-img-quantity">
                  <img src={item.img} alt="product-img" />
                  <span>{item.purchaseQuantity}</span>
                </div>
                <div>
                  <p className="checkout-products-title">{item.title}</p>
                  <p>${totalProductPrice}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="checkout-product-pricing">
        <div className="checkout-product-price-div">
          <p>Sub total</p>
          <p>${subTotal}</p>
        </div>

        <div className="checkout-product-price-div">
          <p>Shipping</p>
          <p>FREE</p>
        </div>

        <div className="checkout-product-price-div">
          <h4>Total</h4>
          <h4>${subTotal}</h4>
        </div>

        <Button text="Place order" onClick={HandleButton} />
      </div>

      {popup && (
        <PopupForm
          title="Checkout failed"
          message="All fields are required in delievery information"
          onClose={() => setPopup(false)}
          showPopup={popup}
        />
      )}
    </div>
  );
}
