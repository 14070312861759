import React, { useEffect, useState, useRef } from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GET_METHOD } from '../../../api/api';
import OurBestSellerCard from '../../HomePageComponents/OurBestSellers/OurBestSellerCard/OurBestSellerCard';
import { useDispatch, useSelector } from 'react-redux';
import { setWindowWidth } from '../../../stores/slice';

export default function RelatedProducts({ categoryId }) {
    const [products, setProducts] = useState([]);
    const dispatch = useDispatch();

    const windowWidth = useSelector((state => state.store.Screenwidth));
 
    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            if (newWidth !== windowWidth) {
                dispatch(setWindowWidth(newWidth));
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [windowWidth, dispatch]);

   
    const getSlidesSettings = (windowWidth, productCount) => {
        const isVeryBigScreen = windowWidth >= 1500;
        const isBigScreen = windowWidth > 1200 && windowWidth < 1500;
        const isSingleProduct = productCount === 1;
        const isSmallScreen = windowWidth < 550;
        const isMobileBigScreen = windowWidth > 550 && windowWidth < 700;
        const isMediumScreen = windowWidth >= 700 && windowWidth <= 1200;

        if (isSmallScreen) {
            return {
                slidesToShow: isSingleProduct ? 1 : 1,
                slidesToScroll: isSingleProduct ? 1 : 1,
            };
        }

        if (isMediumScreen) {
            return {
                slidesToShow: isSingleProduct ? 1 : 3,
                slidesToScroll: isSingleProduct ? 1 : 3,
            };
        }

        if (isMobileBigScreen) {
            return {
                slidesToShow: isSingleProduct ? 1 : 2,
                slidesToScroll: isSingleProduct ? 1 : 2,
            };
        }

        if (isBigScreen) {
            return {
                slidesToShow: isSingleProduct ? 1 : 4,
                slidesToScroll: isSingleProduct ? 1 : 4,
            };
        }

        if (isVeryBigScreen) {
            return {
                slidesToShow: isSingleProduct ? 1 : 5,
                slidesToScroll: isSingleProduct ? 1 : 5,
            };
        }

        return {
            slidesToShow: 1,
            slidesToScroll: 1,
        };
    };
    const sliderRef = useRef(null);

    const NextArrow = ({ onClick }) => (
        <div className="custom-arrow-our-best-seller next-arrow-our-best-seller" onClick={onClick}>
            &#10095;
        </div>
    );

    const PrevArrow = ({ onClick }) => (
        <div className="custom-arrow-our-best-seller prev-arrow-our-best-seller" onClick={onClick}>
            &#10094;
        </div>
    );

    const { slidesToShow, slidesToScroll } = getSlidesSettings(windowWidth, products.length);

    const sliderSettings = {
        infinite: products.length > 1,
        speed: 500,
        slidesToShow,
        slidesToScroll,
        nextArrow: <NextArrow onClick={() => sliderRef.current.slickNext()} />,
        prevArrow: <PrevArrow onClick={() => sliderRef.current.slickPrev()} />,
        dots : windowWidth <= 1050 ? true : false
    };

    useEffect(() => {
        const fetchProducts = async () => {
            const res = await GET_METHOD(`https://knitting.azurewebsites.net/api/ECommerceApi/GetItemCategoryWise?CampusId=50&CategoryId=${categoryId}`);
            setProducts(res || []);
        }
        fetchProducts();
    }, [categoryId]);

    return (
        <div className='main-our-best-sellers' style={windowWidth === 700 ? { padding: '2.8rem' } : {}} >
            <div className='best-seller-slider'>
                <Slider {...sliderSettings} >
                    {products?.map((product, index) => {
                        return (
                            <OurBestSellerCard
                                title={product?.ItemName}
                                price={product?.Price}
                                img={product?.ItemImage}
                                Id={product?.ItemId}
                                SaleWithOutStock={product?.SaleWithOutStock}
                                ItemQty={product?.ItemQty}
                                key={index}
                            />
                        )
                    })}
                </Slider>
            </div>
        </div>
    )
}
