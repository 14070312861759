import React from 'react';
import "./CheckoutPage.css"
import Navbar from "../../Component/Navigation/Navbar/Navbar"
import CheckoutForm from "../../Component/CheckoutComponents/CheckoutForm/CheckoutForm"
import CheckoutProducts from '../../Component/CheckoutComponents/CheckoutProducts/CheckoutProducts';

export default function CheckoutPage() {
    return (
        <div className='main-checkout-page'>
            <Navbar />
            <div className='sub-checkout-div'>
                <CheckoutForm />
                <CheckoutProducts />
            </div>
        </div>
    );
}

