import React from 'react';
import "./AllProducts.css"
import Card from '../Card/Card';
// import { useNavigate } from 'react-router-dom';


export default function AllProducts({ apiData }) {
    const truncateTitle = (title, length) => {
        if (title.length > length) {
            return title.slice(title, length) + '...';
        }

        if (title.length <= length) {
            return title;
        }
    }

 
    return (
        <div className='main-all-products' >
            {apiData?.length > 0 ? apiData?.map((pro, index) => {
                return (
                    <Card
                        img={pro?.ItemImage}
                        title={truncateTitle(pro?.ItemName, 28)}
                        price={pro?.Price}
                        id={pro?.ItemId}
                        SaleWithOutStock={pro?.SaleWithOutStock}
                        ItemQty={pro?.ItemQty}
                        CategoryName={pro?.CategoryName}
                        Currency={pro?.Currency}
                        key={index}
                    />
                )
            }) :
                <div className='main-all-products'>
                    {/* <h1 className='heading-allproducts'>No Products found</h1> */}
                </div>
            }
        </div>
    );
}

