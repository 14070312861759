import React, { useEffect, useState } from 'react';
import "./Card.css"
import { useNavigate } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import Button from "../../Button/Button"

// { img, price, title, id, ItemQty, CategoryName, SaleWithOutStock, Currency }

export default function Card({ img, price, title, id, SaleWithOutStock, ItemQty }) {

    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const replaceSpacesWithHyphens = (str) => {
        return str.replace(/\s+/g, '-').toLowerCase();
    }

    return (
        <div className='main-card'>
            <div onClick={windowWidth > 850 ? () => navigate(`/${replaceSpacesWithHyphens(title)}/${id}`) : null} style={{ cursor: 'pointer' }} >
                <img className='card-img' src={img} alt={title} />
                <p className='card-title' onClick={windowWidth < 768 ? () => navigate(`/${replaceSpacesWithHyphens(title)}/${id}`) : null} >{title}</p>
                <Rating readonly initialValue={4.5} size={17} className='rating-cart' />
                <div className='card-price-stock'>
                    <p className='card-price' >${price}</p>
                    {(SaleWithOutStock === true) || (SaleWithOutStock === false && ItemQty > 0) ?
                        <p className='card-stock-instock'  > Instock </p> :
                        <p className='card-stock-outstock'  >
                            Out of Stock
                        </p>
                    }
                </div>
            </div>
            <div className='view-btn'>
                <div>
                    <Button
                        text="View Details"
                        style={{ lineHeight: 0 }}
                        className='view-detail-cart-btn'
                        onClick={() =>navigate(`/${replaceSpacesWithHyphens(title)}/${id}`)}
                    />
                </div>
            </div>
            {/* {popup ?
                <Popup
                    showPopup={popup}
                    message="Product added to cart!"
                    onClose={() => setPopup(false)}
                    img={img} title={title}
                    usedFor='cart' />
                : null}
            {popupWishList ?
                <Popup
                    showPopup={popupWishList}
                    message="Product added to Wishlist!"
                    onClose={() => setPopupWishList(false)}
                    img={img} title={title}
                    usedFor='wishlist'
                />

                : null} */}
        </div>
    );
}

