import React, { useRef } from 'react';
import './ShopNow.css';
import img1 from "../../../assets/shopnow-1.webp"
import img2 from "../../../assets/shopnow-2.webp"
import img3 from "../../../assets/shopnow-3.webp"
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Rating } from 'react-simple-star-rating'
import { useSelector } from 'react-redux';


export default function ShopNow() {
  const sliderRef = useRef(null);

  const NextArrow = ({ onClick }) => (
    <div className="custom-arrow-shopnow next-arrow-shopnow" onClick={onClick}>
      &#10095;
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div className="custom-arrow-shopnow prev-arrow-shopnow" onClick={onClick}>
      &#10094;
    </div>
  );

  const windowWidth = useSelector((state) => state.store.Screenwidth);

  const sliderSettings = {
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow onClick={() => sliderRef.current.slickNext()} />,
    prevArrow: <PrevArrow onClick={() => sliderRef.current.slickPrev()} />,
    dots : windowWidth <= 1050 ? true : false
  };


  return (
    <div className='main-shopnow' >
      <Slider {...sliderSettings} >
        <div className='slider-div'  >
          <div className='sub-shopnow'>
            <div className='text-shopnow'>
              <p className='shopnow-bold-p'>People are talking</p>
              <Rating readonly initialValue={5} size={17} className='rating-shop-now' />
              <p className='shopnow-p'>This is the third made-to-order jacket I have purchased from The Jacket Maker. I've been extremely pleased with all three.
                They all fit perfectly and are made with great standards. Made-to-order/shipping time was also very fast.</p>
            </div>

            <div className='img-shopnow'>
              <img src={img1} className='jacket-img' alt='jacket-img' />
            </div>
          </div>
        </div>

        <div className='slider-div' >
          <div className='sub-shopnow'>
            <div className='text-shopnow'>
              <p className='shopnow-bold-p'>People are talking</p>
              <Rating readonly initialValue={4} size={17} className='rating-shop-now' />
              <p className='shopnow-p'>Love this jacket. Got it made to measure and it fits perfectly.
                Quality construction and even though the jacket is made to order,
                the website photo is pretty accurate to how it really looks. Recommended.</p>
            </div>

            <div className='img-shopnow'>
              <img src={img2} className='jacket-img' alt='jacket-img' />
            </div>
          </div>
        </div>

        <div className='slider-div' >
          <div className='sub-shopnow'>
            <div className='text-shopnow'>
              <p className='shopnow-bold-p'>People are talking</p>
              <Rating readonly initialValue={4} size={17} className='rating-shop-now' />
              <p className='shopnow-p'>The quality of this item is superb. The leather is soft and supple and I found the fit
                was excellent. I am really, really pleased with it. Casual, elegant, and distinctive.</p>
            </div>

            <div className='img-shopnow'>
              <img src={img3} className='jacket-img' alt='jacket-img' />
            </div>
          </div>
        </div>
      </Slider>

    </div>
  )
}
