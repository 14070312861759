import React from 'react'
import './SalientFeatures.css'
import { FaRegCheckCircle } from "react-icons/fa";
import { BsArrowReturnLeft } from "react-icons/bs";
import { TbTruckDelivery } from "react-icons/tb";

export default function SalientFeatures() {
    return (
        <div className='salient-features'>
            <div className='salient-feature-divs'>
                <div>
                    <BsArrowReturnLeft className='salient-feature-icons' />
                </div>
                <div className='salient-text-div'>
                    <h4>Returns</h4>
                    <p>Free return within 14 days from the date of purchase</p>
                </div>

            </div>

            <div className='salient-feature-divs'>
                <div>
                    <FaRegCheckCircle className='salient-feature-icons' />
                </div>
                <div className='salient-text-div'>
                    <h4>Order Tracking</h4>
                    <p>Get real-time order tracking via email from purchase to delivery</p>
                </div>

            </div>

            <div className='salient-feature-divs'>
                <div >

                    <TbTruckDelivery className='salient-feature-icons' />
                </div>
                <div className='salient-text-div'>
                    <h4>Delivery</h4>
                    <p>Free delivery for all products for order over $100</p>
                </div>

            </div>
        </div>
    )
}
