import React, { useEffect, useState } from 'react';
import './Header.css';
import img1 from "../../../assets/banner5.webp";
import img2 from '../../../assets/banner6.webp';
import img3 from '../../../assets/banner4.jpg';
import img1Mob from "../../../assets/img1Mobile.webp"
import img2Mob from "../../../assets/img2Mobile.webp"
import img3Mob from "../../../assets/img3Mobile.webp"

export default function Header() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const images = [
        {
            img: img1,
            heading: 'Elevate Your Style with Our Latest Collection',
            mobileImg: img1Mob
        },
        {
            img: img2,
            heading: 'New Collection, Leather Jackets, Bags & Patches for All! ',
            mobileImg: img2Mob
        },
        {
            img: img3,
            heading: 'Elevate Your Style with Our Latest Collection',
            para:"Leather Jackets for Men, Women, and Kids, Plus Premium Leather Bags and Custom Leather Patches!",
            mobileImg: img3Mob
        }
    ];

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const nextSlide = () => {
        setCurrentSlide((currentSlide + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentSlide((currentSlide - 1 + images.length) % images.length);
    };

    return (
        <div className="carousel" style={{ '--current-slide': currentSlide }}>
            <div className="carousel-content">
                {images.map((image, index) => (
                    <div className="carousel-image-container" key={index}>
                        <img src={windowWidth < 1025 ? image?.mobileImg : image?.img} alt={`Slide ${index + 1}`} className="carousel-image" />
                        <div className={index === 2 ? "carousel-text-center" : index === 1 ?  'carousel-text-2' : 'carousel-text'} >
                            <div>
                                <h2>{image?.heading}</h2>
                                {index === 2 ? <p>{image?.para}</p>: null}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <button className="carousel-button prev" onClick={prevSlide}>&#10094;</button>
            <button className="carousel-button next" onClick={nextSlide}>&#10095;</button>
        </div>
    );
}
