import React from 'react'
import Navbar from '../../Component/Navigation/Navbar/Navbar'
import TermsConditions from '../../Component/AboutComponents/TermsConditions/TermsConditions'
import Footer from '../../Component/Footer/Footer'

export default function TermsConditionsPage() {
  return (
    <div>
      <Navbar />
      <div style={{paddingTop : '7rem'}}>
        <TermsConditions />
        <Footer /> 
      </div>
    </div>
  )
}
