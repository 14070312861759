import React, { useState } from 'react';
import "./Popup.css";
import { useNavigate } from 'react-router-dom';
import Cart from "../Cart/Cart";

export default function Popup({ showPopup, message, onClose, img, title, usedFor, form }) {
    const navigate = useNavigate();
    const [isCartOpen, setCartOpen] = useState(false);

    const openCart = () => setCartOpen(true);
    const closeCart = () => setCartOpen(false);

    if (!showPopup) return null;

    const handleClick = () => {
        if (usedFor === 'cart') {
            openCart();
        } else {
            navigate("/wishlist");
        }
    };

    return (
        <>
            {form ?
                <div className='popup-overlay'>
                    <div className='popup-content-form'>
                        <button className="popup-close-btn" onClick={onClose}>×</button>
                        <div className="popup-text">
                            <p>{message}</p>
                        </div>
                    </div>

                </div>
                :
                <div className="popup-overlay">
                    <div className="popup-content">
                        <button className="popup-close-btn" onClick={onClose}>×</button>
                        <div className="popup-inner" onClick={handleClick}>
                            <img className="popup-image" src={img} alt={title} />
                            <div className="popup-text">
                                <h3>{title}</h3>
                                <p>{message}</p>
                            </div>
                        </div>
                    </div>
                    {isCartOpen && <Cart isOpen={isCartOpen} onClose={closeCart} />}
                </div>
            }
        </>
    );
}
