import React from 'react'
import Navbar from '../../Component/Navigation/Navbar/Navbar'
import RefundPolicy from '../../Component/AboutComponents/RefundPolicy/RefundPolicy'
import Footer from '../../Component/Footer/Footer'

export default function RefundPolicyPage() {
    return (
        <div>
            <Navbar />
            <div style={{paddingTop : '7rem'}}>
                <RefundPolicy />
                <Footer />
            </div>

        </div>
    )
}
