import React from 'react';
import './PrivacyPolicy.css';

export default function PrivacyPolicy() {
    return (
        <div className="container">
            <header className="header">
                <h1>Privacy & Policy</h1>
            </header>

            <section className="section">
                <p>Verifone Payments B.V., Avangate Inc. or any of their affiliates doing business
                    as 2Checkout (each "Avangate," "2Checkout", "We," "Us" or "Our") is committed
                    to safeguarding your privacy and ensuring that you continue to trust Us with
                    Your personal information.</p>

                <p>
                    <b>
                        Customarily, We collect personal information only directly from you
                        (contact data, payment, delivery details etc.). We will tell You what personal
                        information is mandatory when placing an Order.
                    </b>
                </p>

                <p>
                    As Our shopper, You provide Us with contact data, such as Your name, surname, postal or
                    email addresses, phone number; transaction details, such as information about the
                    Products You buy, credit card number and other credit card details; renewal options,
                    geo-location data of Your mobile or other device, including GPS-based, Wi-Fi-based,
                    or cell-based location information. We will mention which information is mandatory
                    in order for You to successfully place an Order.
                </p>

                <p>
                    Even if You are not a shopper, You may provide Us with contact data, for example when you set
                    up an account with 2Checkout or when you enlist to our marketing communication thread.
                </p>

                <p>
                    We will interact with you (a) online, (b) offline or (c) when you interact with online
                    targeted content (such as advertisements) that We or Our service providers provide to
                    You via third party websites or applications.
                </p>

                <p>
                    <b>
                        In specific cases, personal information about You is collected by Us automatically.
                    </b>
                </p>

                <p>
                    We may collect personal information about You via automated means (such as cookies)
                    when You interact with our ads, apps, or visit Our Websites. In such a case,
                    personal information We collect may include IP address, browser type, operating
                    system, mobile device identifier, geographical area, referring URLs.
                </p>

                <p>
                    <b>
                        You may also provide personal information about others (for example when You place
                        an Order on other’s behalf). If so, You are responsible for the entire data
                        You provide to Us (and We assume that You are authorized to give such data).
                    </b>
                </p>

                <p>
                    In case the personal information provided by You concerns other persons, it will be
                    Your responsibility to obtain prior authorization.
                </p>

                <p>
                    Our focus is to process Your personal information only for specific purposes that allow
                    Us to service You in the best possible manner or, otherwise to manage an interaction
                    with Us.
                </p>

                <p>2Checkout may store and use Your Personal Information and, without further notice to
                    You, provide Your Personal Information to 2Checkout affiliates or to third parties
                    in order for 2Checkout, its affiliates or third parties:</p>

                <ul>
                    <li>to process an Order from You, including processing Your online payment
                        and notifying You of the Order status,;</li>

                    <li>
                        to invoice You for the Products ordered by You;
                    </li>

                    <li>
                        to deliver the Products ordered by You;
                    </li>

                    <li>
                        to support Our intent to maintain and improve the security of Your Order and
                        transactions (e.g., by applying antifraud filters);
                    </li>

                    <li>
                        to register Your purchase with the Merchant (for warranty, technical support, or other purposes);
                    </li>

                    <li>
                        to provide You with shopper and technical support;
                    </li>

                    <li>
                        to inform You about Product upgrades; special offers; other products, services and
                        information (including from third parties); market research; or completing surveys
                        or evaluations; if You have opted to receive such communications;
                    </li>

                    <li>
                        to support Our intent to show You the most relevant content and improve usability of the Website;
                    </li>

                    <li>
                        to provide You with access to restricted areas of the Website;
                    </li>

                    <li>
                        to comply with legal requirements;
                    </li>

                    <li>
                        to enforce the terms and conditions of this Agreement, including the investigation
                        of potential violations; or
                    </li>

                    <li>
                        for any other lawful purposes.
                    </li>
                </ul>

                <p>Depending on the relationship You have with Us, the processing necessary to
                    fulfil Our legitimate interest may be: to remind You about your abandoned shopping
                    cart, to request Your feedback, to create and maintain Your 2Checkout myAccounts,
                    to protect against, identify and prevent abuse, fraud and other types of crime,
                    claims and other liabilities. You are entitled to oppose to such processing, and
                    we will review your request and respond to it on a case by case basis.</p>

                <p>
                    Some data, such as the types of used services and how many users We receive daily,
                    may be used by 2Checkout for statistical, marketing, promotional, or any other
                    lawful purposes. This kind of information is collected in aggregate/statistical form,
                    without identifying any user individually.
                </p>

                <p>
                    In any case, We will not subject You to a decision based solely on automated processing
                    that produces legal effects concerning you or similarly significantly affects You,
                    unless you explicitly consented to the processing, the processing is necessary
                    for entering into, or performance of a contract between You and Us, or when We are
                    legally required / entitled to use Your personal data in this way, for example
                    to prevent fraud.
                </p>

                <p>
                    <b>
                        You must be older than 16 years in order to place an order with Us if you reside
                        in EU. Otherwise, children’s personal information is cautiously processed
                        (only with specific safeguards).
                    </b>
                </p>

                <p>
                    We do not knowingly solicit or collect personal information from children below the
                    age of 16 when offering services of an informational society (except if explicit consent
                    from parent or custodian is obtained in prior). This is applicable only for those
                    residing in EU.
                </p>

                <p>
                    If We discover that accidentally We collected personal information from a child
                    below this age, We will remove that child’s personal information from Our records
                    as soon as reasonably possible.
                </p>

                <p>
                    <b>
                        In order to process Your Order, We will share Your personal information with our partners.
                    </b>
                </p>

                <p>
                    Without any prior notice, 2Checkout may share your personal information with third parties
                    for other purposes, but only in the following circumstances:
                </p>

                <ul>
                    <li>Affiliates: we may provide your personal information to affiliates or related companies for legitimate business purposes,</li>
                    <li>Merchants and their affiliates or partners: 2Checkout is an ecommerce platform
                        that enables You to access worldwide Products and as such 2Checkout must share
                        Your personal information for the purpose of You processing Your Order,</li>
                    <li>Service providers: 2Checkout may engage service providers, consultants,
                        external partners, agents or contractors to provide services on its behalf,
                        including administering our media and services available to you. These third
                        parties may come to access or otherwise process Your personal information
                        in the course of providing these services. 2Checkout requires such third parties,
                        to comply with all relevant data protection laws and security requirements
                        in relation to Your personal information, by way of a written agreement.</li>

                    <li>
                        Public Authorities: 2Checkout may disclose Your personal information if it is required
                        to do so by law or if, in Ours good faith judgment, such legal disclosure is
                        reasonably necessary to comply with legal processes or respond to any claims.
                    </li>
                </ul>

                <p>Some of Our services are provided through sites that bear Our partners' or suppliers'
                    names and trademarks and are not in Our control, and this Website may contain links
                    or other devices that take You to other websites that are also not in Our control.
                    Certain of those other websites may provide products or services to You directly
                    or on Our behalf. We are not responsible for any aspect of other websites,
                    including, without limitation, the privacy practices, products, services,
                    or content of such websites. This Privacy Policy will not apply to such other
                    websites. Rather, Your activity and the information that You provide at such
                    other websites will be subject to the privacy policy and other terms and
                    conditions posted at such other websites.</p>

                <p>
                    <b>You have specific rights in respect of data protection and We foster an
                        environment facilitating their exercise (right to access, rectify, object,
                        delete, receive your personal information or to complaint).</b>
                </p>

                <p>
                    You have the right to:
                </p>

                <ul>
                    <li>access your personal information, rectify it, restrict or object to its
                        processing, or request its deletion,</li>

                    <li>to receive the personal information You provided to Us or to transmit it to another company,</li>

                    <li>to withdraw any consent provided,</li>

                    <li>to opt out of some collection or uses of Your personal information, including
                        the use of cookies and similar technologies, the use of Your personal
                        information for marketing purposes, and the use for data analyses,</li>

                    <li>where applicable, to lodge a complaint with your supervisory authority.</li>
                </ul>

                <p>In accordance with the applicable law, 2Checkout will provide You with access to
                    Your Personal Information and, as appropriate, the right to intervene in respect
                    of Your Personal Information. You may also be entitled to object to the processing
                    of Your Personal Information by 2Checkout or to request the deletion of Your
                    Personal Information, in which case, if 2Checkout in its sole discretion deems
                    such Personal Information as mandatory, 2Checkout may no longer permit You
                    to engage in certain activities.</p>

                <p>
                    You can decide if You want to provide Your Personal Information to 2Checkout, but if
                    You do not provide certain Personal Information, You may not be able to make purchases,
                    enlist services or access certain options at the Website or through other means.
                    If You choose at the Website to receive communications regarding product information,
                    special offers, or any other marketing or other information, We may add Your
                    Personal Information to Our email database and use (or direct Our designees to use)
                    Your Personal Information for providing such communications to You. You may later
                    choose to unsubscribe from receiving such communications by following the
                    unsubscribe directions in Our communications. If You provide 2Checkout with
                    inconsistent privacy preferences at the Website (for example, by indicating on
                    one occasion that We may provide You with marketing offers and on another occasion
                    that We may not), 2Checkout will endeavor to update Your privacy preferences
                    in 2Checkout's records with Your latest preference in a timely manner.
                </p>

                <p>
                    <b>
                        If you are residing in the European Union, Your Personal information may be
                        transferred outside the European Union or the European Economic Area.
                    </b>
                </p>

                <p>
                    Personal Information provided to 2Checkout by You, the Merchants, or other third parties,
                    is primarily processed and stored on the servers located at Our offices within
                    The Netherlands and/or the United States. We may change the location of the servers
                    from time to time to other countries and without prior notice to You; however,
                    Personal Information will continue to be protected in accordance with this Privacy
                    Policy.
                </p>

                <p>
                    Your Personal Information may be transferred to persons within the European Union,
                    Switzerland or to persons within other countries whose legislation may or may not
                    ensure an adequate level of protection. We shall endeavor to comply with the laws
                    of the country from which Your Personal Information was transferred, if We consider
                    the laws of the country to which Your Personal Information was transferred as not
                    ensuring an adequate level of protection.
                </p>

                <p>We participate in the United States - European Union Privacy Shield and Swiss -
                    United States Privacy Shield Frameworks. Please see Our Privacy Shield Policy.</p>

                <p>
                    <b>
                        Your California Privacy Rights
                    </b>
                </p>

                <p>
                    At 2Checkout we never monetize your information and we are informing you on our data
                    processing purposes through this Privacy Policy. We will not share personal information
                    we collect about you except to the extent permitted under California law. If you are
                    a California resident, we want to make sure you have everything you need to make
                    informed decisions. Subject to certain limits under California law, You have the
                    right to: know how your personal information is used; access, request and receive
                    the personal information we have collected in a portable manner; object to having
                    your data sold or shared, and request that we delete your personal data. You can
                    make requests about your privacy rights at dpo@2checkout.com or using other
                    alternative contact details as per https://www.2co.com/#contactUs
                </p>

                <p>
                    <b>
                        We installed adequate safeguards to secure Your personal information and We also
                        implemented specific time periods so that to ensure that Your data is not retained
                        longer than necessary for the stated purpose.
                    </b>
                </p>

                <p>
                    <b>Data security.</b> 2Checkout has implemented industry-standard measures to protect the
                    security of the Personal Information We collect via the Website. For example,
                    We use (SSL - Secure Socket Layers), a firewall and a digital certificate issued
                    by VerisignTM to protect certain of Your Personal Information, and We are
                    PCI DSS (Payment Card Industry Data Security Standard) certified. No one can
                    guarantee, and We do not guarantee, that Your Personal Information is completely
                    secure at all times, however We ensure You that the security of Your personal
                    information is Our primary concern. You are also responsible for maintaining the
                    confidentiality of Your Personal Information to protect it against unauthorized
                    access or use.
                </p>

                <p>
                    <b>Retention.</b> We will only retain Your personal information for as long as
                    You continue to use the Website or Products, and thereafter as permitted or
                    required by applicable law, taking into account also our need to answer queries
                    or resolve problems, provide improved and new services, and comply with legal
                    requirements under applicable laws. This means that we may retain Your personal
                    information for a reasonable period after your last interaction with us
                    (as required by fiscal law, otherwise no longer than what is necessary to protect
                    our legitimate interests, to defend against a claim or what is necessary pursuant
                    to regulatory provisions).
                </p>

                <p>When the personal information that We collect is no longer required in this way,
                    we anonymize or delete it in a secure manner.</p>

                <p>
                    <b>
                        We have a dedicated point for you to contact us in any privacy matters (dpo@2checkout.com).
                    </b>
                </p>

                <p>
                    For the purpose of exercising Your rights, You shall submit a written request to
                    2Checkout in accordance with the applicable law to the applicable 2Checkout office
                    identified in the "Contact Us" section of the Website, as per the dedicated contact
                    details to dpo@2checkout.com . In addition, if You believe that 2Checkout has not
                    complied with this Privacy Policy with respect to Your Personal Information,
                    please submit a sufficiently detailed complaint to info@2checkout.com, or by mail
                    to the applicable 2Checkout office identified in the "Contact Us" section of the
                    Website. We will promptly investigate Your complaint. Thereafter, We will take all
                    measures We deem necessary to remedy the issues outlined in Your complaint that
                    We, in Our sole discretion, view as justified.
                </p>

                <p>
                    All your options and messages will be responded based on the law requirements
                    (one month since your request or longer if necessary). Nevertheless, please consider
                    that in order to implement your requests or options we may need several days due
                    to technical reasons (during such period we may continue to process your personal
                    information based on your past options).
                </p>

                <p>

                    If You have any questions about this Privacy Policy, please send Your inquiry to
                    info@2checkout.com or to the applicable office identified in the "Contact Us" section
                    of the Website.
                </p>

                <p>
                    2Checkout reserves the right to change this Privacy Policy at any time by posting
                    notice of the changes on the Website. For additional details concerning changes
                    to this Privacy Policy, please see Section 18.3 of the 2Checkout Terms and Conditions.
                </p>

            </section>

        </div >
    );
}

