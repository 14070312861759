import React from 'react';
import "./RefundPolicy.css"

export default function RefundPolicy() {
    return (
        <div className="container">
            <header className="header">
                <h1>Refund Policy & Returns </h1>
            </header>

            <section className="section">
                <p>  To place a refund, return or exchange request for an order placed with 2Checkout
                    (now Verifone), You would need to contact 2Checkout (now Verifone) via email at
                    support@2checkout.com, by phone at +31 88 000 0008 or by accessing 2Checkout
                    (now Verifone) myAccount.</p>

                <p>Please have your order number available and provide a reason for your request in order
                    to speed up your refund process. Your request will be reviewed within 2 days and
                    we will notify you via email of the results.</p>

                <p>Refund, returns or exchanges request are accepted up to 30 days from the date of
                    placing the Order with 2Checkout (now Verifone). 2Checkout (now Verifone) may
                    grant extensions to this period for some special cases and Products at its
                    discretion. </p>

                <p>
                    If you choose to pay by check or money order you may cancel your Order before making
                    the payment or in case the payment is not received by us in 30 days 2Checkout
                    (now Verifone) will cancelled you Order.
                </p>

                <p>
                    You may submit a request to cancel your order on the same day the order was placed.
                    Please note that if the Order is already shipped or delivered we will not be able
                    to approve the cancelation request.
                </p>

                <p>
                    Some products have special conditions for returns as described in the Order or Product
                    materials delivered ("Products terms") so please read the carefully before making a
                    request with 2Checkout (now Verifone). In case of misunderstandings between
                    2Checkout (now Verifone) Refund Policy and the Product terms, the present
                    Refund policy will prevail. Shipping costs are non-refundable, except if due
                    to 2Checkout (now Verifone) error, when you need to contact 2Checkout (now Verifone)
                    and make a formal request.
                </p>

                <p>If the refund request is granted by 2Checkout (now Verifone), payments are refunded as follows:</p>
                <ul>
                    <li>Credit / Debit Cards payments will be refunded within one (1) business days;</li>
                    <li>Wire Transfer and check payments will be refunded within seven (7) business days and the cost of transfer will be supported by the end user;</li>
                    <li>PayPal payments will be refunded within one (1) business day;</li>
                    <li>Other payment methods will be refunded between five (5) to seven (7) days.</li>
                </ul>

                <p>If applicable, 2Checkout (now Verifone) reserve the right to hold the refund payment until we receive a completed letter of destruction of the Product signed by you.</p>

                <p>The present Refund policy is part of the 2Checkout (now Verifone) TERMS AND CONDITIONS.</p>
            </section>

        </div>
    );
}

