import React from 'react'
import "./CategoryComponent.css"
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

export default function CategoryComponent() {
    const categories = useSelector((state) => state.store.category);
    const navigate = useNavigate();

    const replaceSpacesWithHyphens = (str) => {
        return str.replace(/\s+/g, '-').toLowerCase();
    }

    return (
        <div className='main-category-component'>
            <h1 className='main-category-heading'>Product Categories</h1>
            <div className='blog-container-category'>
                <div className='large-blogs'>
                    {categories.slice(0, 2).map((cat) => (
                        <div
                            key={cat.Id}
                            className='large-blog'
                            onClick={() => navigate(`/category/${replaceSpacesWithHyphens(cat?.CategoryName)}/${cat?.Id}`)}
                            style={{ backgroundImage: `url(${cat?.CategoryImage})` }}
                        >
                            <div className='text-div-category'>
                                <h1 className='category-image-heading'>{cat?.CategoryName}</h1>
                                <p className='category-image-description'>
                                    {cat?.Description}
                                </p>
                                <button className='category-btn'>Shop Now</button>
                            </div>
                        </div>
                    ))}
                </div>
                
                <div className='small-blogs'>
                    {categories.slice(2).map((cat) => (
                        <div
                            key={cat?.Id}
                            className='small-blog'
                            onClick={() => navigate(`/category/${replaceSpacesWithHyphens(cat?.CategoryName)}/${cat?.Id}`)}
                            style={{ backgroundImage: `url(${cat.CategoryImage})` }}
                        >
                            <div className='text-div-category'>
                                <h1 className='category-image-heading'>{cat.CategoryName}</h1>
                                <p className='category-image-description'>
                                    {cat.Description}
                                </p>
                                <button className='category-btn'>Shop Now</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
