import React from 'react';
import './AllAboutLeather.css';

function AllAboutLeather() {
    return (
        <div className="container">
            <header className="header">
                <h1>All About Leather</h1>
                <p>Explore the intricate world of leather and the processes that make it unique.</p>
            </header>

            <section className="section">
                <h2>Types of Leather</h2>
                <p>Leather comes in various grades, each with distinct characteristics and uses. The most common types include:</p>
                <ul>
                    <li><strong>Full-Grain Leather:</strong> The finest quality leather, retaining the natural grain and imperfections for a rugged, authentic look.</li>
                    <li><strong>Top-Grain Leather:</strong> Slightly sanded to remove surface imperfections, resulting in a smooth, refined finish.</li>
                    <li><strong>Genuine Leather:</strong> A mid-range leather made from the remaining layers after the top grain is removed.</li>
                    <li><strong>Bonded Leather:</strong> Composed of shredded leather fibers bonded together, often used in budget-friendly products.</li>
                </ul>
            </section>

            <section className="section">
                <h2>Tanning Processes & Techniques</h2>
                <ul>
                    <li><strong>Chrome Tanned:</strong> Leather tanned using chromium sulfate, a fast and efficient process.</li>
                    <li><strong>Oil-Tanned:</strong> Leather treated with specific oils during tanning and dyeing for added suppleness.</li>
                    <li><strong>Vegetable Tanned:</strong> Leather tanned through soaking in a solution made from natural vegetable tannins.</li>
                    <li><strong>Brain Tanned:</strong> A traditional method using emulsified oils, often derived from animal brains.</li>
                    <li><strong>Blue:</strong> Hides that have undergone chrome tanning but haven't been dyed.</li>
                    <li><strong>Chrome Re-tan:</strong> Leather initially tanned with chromium sulfate and then re-tanned using vegetable tannins.</li>
                    <li><strong>Crust:</strong> Vegetable-tanned leather that hasn't yet been finished.</li>
                    <li><strong>Currying:</strong> A process that enriches leather with oils, waxes, and other materials to enhance its flexibility.</li>
                    <li><strong>Deer-tanned cow:</strong> Cowhide processed to mimic the softness and feel of deer leather.</li>
                    <li><strong>Hair-on Leather:</strong> Leather tanned without removing the hair from the hide, preserving its natural texture.</li>
                    <li><strong>Mineral Tanned:</strong> Leather tanned using naturally occurring mineral compounds.</li>
                    <li><strong>Oak Tannage:</strong> A traditional tanning method that uses oak wood to tan the leather, giving it a distinct character.</li>
                    <li><strong>Rawhide:</strong> Untanned and undyed hide, preserved in its natural state.</li>
                    <li><strong>Re-tannage:</strong> A secondary tanning process applied after the initial tanning, enhancing the leather's properties.</li>
                </ul>
            </section>

            <section className="section">
                <h2>Dyeing Techniques</h2>
                <ul>
                    <li><strong>Bleeding:</strong> A phenomenon where dye runs, often triggered by exposure to water, causing the color to bleed out.</li>
                    <li><strong>Drum Dyeing:</strong> Hides are tumbled in drums filled with stones and dye, resulting in soft, flexible leather.</li>
                    <li><strong>Milling:</strong> A natural process that softens the leather, enhancing its texture.</li>
                    <li><strong>Vat Dyeing:</strong> Dyeing by immersing leather in vats filled with dye solutions, ensuring even coloration.</li>
                </ul>
            </section>

            <section className="section">
                <h2>Leather Aging & Wear</h2>
                <ul>
                    <li><strong>Patina:</strong> The beautiful aging process that gives leather a rich, unique character over time.</li>
                    <li><strong>Cracking:</strong> A sign of aging in lower-quality leather, where the dye begins to crack and separate from the hide.</li>
                    <li><strong>Peeling:</strong> The outer layer of bonded leather can peel away, revealing its lower-quality construction.</li>
                </ul>
            </section>

            <section className="section">
                <h2>Measuring Leather</h2>
                <p>Leather is measured using specific terms and units to ensure consistency in quality and application:</p>
                <ul>
                    <li><strong>Iron:</strong> A unit used to measure leather thickness; one iron equals 1/48th of an inch (0.53mm).</li>
                    <li><strong>Ounces:</strong> Leather weight is measured in ounces, but it can also be expressed in kilograms or pounds depending on the region.</li>
                    <li><strong>Square Feet:</strong> Hides are measured in square feet or yards before being processed.</li>
                </ul>
            </section>

            <section className="section">
                <h2>Types of Leather Stitches</h2>
                <ul>
                    <li><strong>Saddle Stitch:</strong> The most durable stitch, ideal for creating strong, long-lasting seams.</li>
                    <li><strong>Whip Stitch:</strong> Perfect for joining leather pieces together with a simple yet effective stitch.</li>
                    <li><strong>Lock Stitch:</strong> Used for reinforcing leather seams, ensuring they remain secure over time.</li>
                    <li><strong>Cross Stitch:</strong> A decorative stitch that adds visual interest to leather projects.</li>
                    <li><strong>Zigzag Stitch:</strong> A quick and simple stitch suitable for various leather applications.</li>
                </ul>
            </section>
            <section className="section">
                <h2>Types of Tanning & Processes</h2>
                <ul>
                    <li><strong>Chrome Tanned:</strong> Leather treated with chromium sulfate in a fast and efficient process.</li>
                    <li><strong>Oil-Tanned:</strong> Leather infused with specific oils during tanning and dyeing for enhanced durability and flexibility.</li>
                    <li><strong>Vegetable Tanned:</strong> Leather processed by soaking in a natural solution of vegetable tannins for a traditional touch.</li>
                    <li><strong>Brain Tanned:</strong> An ancient method using emulsified oils, often derived from animal brains like deer, cows, or buffaloes.</li>
                    <li><strong>Blue:</strong> Hides that have undergone chrome tanning but remain undyed, giving them a distinctive blue hue.</li>
                    <li><strong>Chrome Re-tan:</strong> Leather initially tanned with chromium sulfate and then re-tanned using vegetable tannins for enhanced properties.</li>
                    <li><strong>Crust:</strong> Vegetable-tanned leather that has not yet been finished or dyed, showcasing its natural texture.</li>
                    <li><strong>Currying:</strong> The process of enriching leather with oils, waxes, and other materials to improve its texture and durability.</li>
                    <li><strong>Deer-Tanned Cow:</strong> Cowhide processed to mimic the softness and appearance of deer leather.</li>
                    <li><strong>Hair-on Leather:</strong> Leather that retains the hair from the hide, providing a unique, natural look.</li>
                    <li><strong>Mineral Tanned:</strong> Leather tanned using naturally occurring mineral compounds for durability and unique characteristics.</li>
                    <li><strong>Oak Tannage:</strong> A vintage tanning technique that uses oak wood, imparting a rich and traditional quality to the leather.</li>
                    <li><strong>Rawhide:</strong> Untanned and undyed hide, preserved in its natural state for various uses.</li>
                    <li><strong>Re-tannage:</strong> A secondary tanning process applied after the initial tanning to refine and enhance the leather’s properties.</li>
                </ul>
            </section>

            <section className="section">
                <h2>Dyeing Techniques</h2>
                <ul>
                    <li><strong>Bleeding:</strong> When dye interacts with water, it can appear as though the color is bleeding out of the leather.</li>
                    <li><strong>Drum Dyeing:</strong> Leather is tumbled in drums with stones and dye, resulting in a softer and more flexible texture.</li>
                    <li><strong>Milling:</strong> A process that naturally softens the leather, enhancing its feel and flexibility.</li>
                    <li><strong>Vat Dyeing:</strong> Leather is immersed in vats containing dye solutions, ensuring thorough and even coloration.</li>
                </ul>
            </section>

            <section className="section">
                <h2>Effects of Leather Aging</h2>
                <ul>
                    <li><strong>Patina:</strong> Over time, leather develops a beautiful patina, adding character and depth to its appearance.</li>
                    <li><strong>Cracking:</strong> Low-quality leather may experience cracking of the dye, indicating deterioration.</li>
                    <li><strong>Peeling:</strong> Low-quality leather, such as bonded leather, may peel, revealing the lower-quality layers beneath.</li>
                </ul>
            </section>

            <section className="section">
                <h2>Measuring Leather</h2>
                <p>Leather is measured using specific terms and units to ensure proper grading and application:</p>
                <ul>
                    <li><strong>Iron:</strong> A unit of measurement for leather thickness, with one iron equaling 1/48th of an inch (0.53mm).</li>
                    <li><strong>Ounces:</strong> Leather weight is measured in ounces, but can also be expressed in kilograms or pounds depending on regional standards.</li>
                    <li><strong>Square Feet:</strong> Hides are measured in square feet or yards before processing to determine size and suitability.</li>
                </ul>
            </section>
        </div>
    );
}

export default AllAboutLeather;
