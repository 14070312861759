import React from 'react'
import Navbar from '../../Component/Navigation/Navbar/Navbar'
import PrivacyPolicy from '../../Component/AboutComponents/PrivacyPolicy/PrivacyPolicy'
import Footer from '../../Component/Footer/Footer'

export default function PrivacyPolicyPage() {
  return (
    <div>
        <Navbar />
        <div style={{paddingTop : '7rem'}}> 
            <PrivacyPolicy />
            <Footer /> 
        </div>
    </div>
  )
}
