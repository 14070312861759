import React, {useState } from 'react';
import './CheckoutForm.css'
import CheckoutDelieveryForm from '../CheckoutDelieveryForm/CheckoutDelieveryForm';
import { setDelieveryInformation } from '../../../stores/slice';
import { useDispatch } from 'react-redux';

export default function CheckoutForm() {
    const [selectedPayment, setSelectedPayment] = useState('payfast');
    // const [billingAddress, setBillingAddress] = useState(0);
    // const [check, setCheck] = useState(false);
    // const [active, setActive] = useState(false);
    // const [phoneEmail, setPhoneEmail] = useState();
    const [formData, setFormData] = useState({});
    const [delieveryFormData, setDelieveryFormData] = useState({});

    const dispatch = useDispatch(); 

    const handlePayment = (event) => {
        setSelectedPayment(event.target.value);
    };

    // const handleCheckBox = (e) => {
    //     setCheck(e.target.checked);
    // }

    // const handleBilling = (value) => {
    //     setBillingAddress(value);
    //     if (value === 1) {
    //         setActive(true);
    //     }
    //     else {
    //         setActive(false);
    //     }
    // }

    const handleForm = () => {
        setFormData({
            delieveryMethod: selectedPayment,
            // billingAddress: billingAddress
        })

        setFormData((prevFormData) => ({
            ...prevFormData,
            ...delieveryFormData,
        }));

        dispatch(setDelieveryInformation(formData));
    }


    return (
        <div className='main-checkoutform'>
            <form className='checkout-form' onChange={handleForm}>
                {/* <h4 className='checkout-form-heading' >Contact</h4> */}
                

                {/* <div className='news-offer-email-div'>
                    <input
                        type='checkbox'
                        checked={check}
                        onChange={handleCheckBox}


                    />
                    <label>Email me with new and offers</label>
                </div> */}

                <CheckoutDelieveryForm onChange={setDelieveryFormData} />

                {/* <h4 className='checkout-form-heading' >Shipping Method</h4>
                <div className='shipping-method-div'>
                    <p>Free Shipping</p>
                    <p>Free</p>
                </div> */}

                <h4 className='checkout-form-heading'>Payment Method</h4>
                <p>All transactions are secured and encrypted</p>
                <div className='checkout-method-div'>
                    {/* <label className={selectedPayment === "COD" ? 'checkout-method-div-label-selected' : 'checkout-method-div-label'}>
                        <input
                            type="radio"
                            name="paymentMethod"
                            value="COD"
                            checked={selectedPayment === "COD"}
                            onChange={handlePayment}
                        /> &nbsp;
                        Cash on Delivery (COD)
                    </label> */}
                    <label className={selectedPayment === "payfast" ? 'checkout-method-div-label-selected' : 'checkout-method-div-label'}>
                        <input
                            type="radio"
                            name="paymentMethod"
                            value="payfast"
                            checked={selectedPayment === "payfast"}
                            onChange={handlePayment}
                        /> &nbsp;
                        PAYFAST (Pay via Debit/Credit/Wallet/Bank Account)
                    </label>
                </div>


                {/* <h4 className='checkout-form-heading'>Billing Address</h4> */}
                {/* <div className='checkout-method-div'>
                    <label className={billingAddress === 0 ? 'checkout-method-div-label-selected' : 'checkout-method-div-label'}>
                        <input
                            type="radio"
                            name="billingAddress"
                            value={0}
                            checked={billingAddress === 0}
                            onChange={() => handleBilling(0)}
                        /> &nbsp;
                        Same as shipping address
                    </label>
                    <label className={billingAddress === 1 ? 'checkout-method-div-label-selected' : 'checkout-method-div-label'}>
                        <input
                            type="radio"
                            name="billingAddress"
                            value={1}
                            checked={billingAddress === 1}
                            onChange={() => handleBilling(1)}
                        /> &nbsp;
                        Use a different billing address
                    </label>
                    {active ?
                        <div className='billing-delievery-form'>
                            <CheckoutDelieveryForm onChange={setDelieveryFormData} />
                        </div>
                        : null}
                </div> */}
            </form>
        </div>
    );
}

