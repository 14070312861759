import React from 'react';
import "./OurBestSellerCard.css";
import { useNavigate } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';


export default function OurBestSellerCard({ Id, price, title, img, SaleWithOutStock, ItemQty }) {
    const navigate = useNavigate();

    const replaceSpacesWithHyphens = (str) => {
        return str.replace(/\s+/g, '-').toLowerCase();
    }

    const handleNavigation = (Id, title) => {
        const productTitle = replaceSpacesWithHyphens(title);
        navigate(`/${productTitle}/${Id}`);
        window.scrollTo(0, 0);
    }
    
    return (
        <div className='main-our-best-seller-card' onClick={() => handleNavigation(Id, title)}>
            <div>
                <div className='our-seller-img-div'>
                    <img src={img} alt='' />
                </div>
                <p className='our-best-seller-title'>{title}</p>
                <div className='our-best-seller-rating-stars'>
                    <Rating size={17} readonly initialValue={4} />
                </div>
                <div className='card-price-stock-ourbest-seller'>
                    <p className='card-price' >${price}</p>
                    {(SaleWithOutStock === true) || (SaleWithOutStock === false && ItemQty > 0) ?
                        <p className='card-stock-instock' > Instock </p> :
                        <p className='card-stock-outstock'  >
                            Out of Stock!
                        </p>
                    }
                </div>
            </div>
        </div>
    )
}
